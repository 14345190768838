/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

.red{
  color: red;
}

.ant-card-body button{
  margin-right: 10px;
  margin-bottom: 10px;
}

.ant-table-wrapper{
  margin-top: 16px;
  margin-bottom: 16px;
}

/* .ant-modal-body .ant-table-wrapper{
  margin-top: 0px;
} */

.ant-pagination{
  float: right;
}

.custom-filter-dropdown {
  padding: 8px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 1px 6px rgba(0, 0, 0, .2);
}

.custom-filter-dropdown input {
  width: 130px;
  margin-right: 8px;
}

.custom-filter-dropdown button {
  margin-right: 8px;
}

.highlight {
  color: #f50;
}

.ant-form.table-search-form{
  margin-top: 16px;
}

.antd-user-group-card .ant-card-body{
  padding: 24px 12px;
}

.ant-modal-body{
  overflow: hidden;
}

.ant-modal-body .ant-btn{
  margin-right: 10px;
}

.ant-table-row .ant-form-item{
  margin-bottom: 0px;
}

.stepPanel {
  padding-top: 20px;
}

.formLabel{
  text-align: right;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 20px;
}

.formRow{
  line-height: 40px;
  margin-bottom: 24px;
}
.ant-form-item-control > .ant-form-item:last-child, .ant-form-item [class^="ant-col-"] > .ant-form-item:only-child{
  margin-bottom: 0px !important;
}

.file_input{
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 2;
}

.ant-modal-body{
  position: relative;
}
.RichEditor-editor .public-DraftEditor-content{
  min-height: 200px !important;
}

.ant-layout-sider-collapsed .logo_title{
  display: none;
}

.logo_box{
  background: rgba(255, 255, 255, .2);
  margin: 10px;
  font-size: 16px;
  color: #ffffff;
  padding: 10px;
  line-height: 40px;
  width: 180px;
}

.logo_box img{
  width: 30px;
  margin-right: 8px;
}

.anticon{
  cursor: pointer
}

.gray {
  background: #f5f5f5;
}

.input_file{
  border: 1px solid #d9d9d9;
    border-radius: 4px;
  position: relative;
}
.input_file:hover{
  border-color: #3BBE64;
  cursor: text;
}
.input_file input{
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
}
.input_file p{
  margin: 0;
  height: 32px;
  line-height: 32px;
  padding: 0px 11px;
}
.input_file i{
  position: absolute;
  right: 10px;
  top: 4px;
  font-size: 24px;
}

.myEmptyStyle .ant-empty-image {
  height: 40px;
}

/* BraftEditor富文本 */
.my-editor {
  border: 1px solid #d1d1d1;
  border-radius: 5px;
}