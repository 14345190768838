#login-page{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background:#DEF3F4;
    z-index: 99;
}

#login-page .container{
    position: relative;
    width: 240px;
    height: 320px;
    padding: 100px 40px 40px 40px;
    box-sizing: content-box;
}

#login-page .box{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 70px 40px 40px 40px;
    backface-visibility: hidden;
    background: linear-gradient(230deg, rgba(53, 57, 74, 0) 0%, rgb(16, 64, 59) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='rgba(53, 57, 74, 0)', endColorstr='rgb(0, 0, 0)',GradientType=1 );
    box-shadow: -15px 15px 15px rgba(0,0,0,.4);
    transition: all 1s;
}
#login-page .showBox{
    transform:rotateY(0deg);
}
#login-page .hiddenBox{
    transform:rotateY(180deg);
}
#login-page .loginBtn{
    padding: 10px 50px;
    border: 2px solid #1cc09f;
    border-radius: 50px;
    background: transparent;
    font-size: 11px;
    color: #1cc09f;
    transition: all .2s;
}
#login-page .bottom{
    display: flex;
    height: 42px;
    justify-content: space-between;
    align-items: center;
}
#login-page .registerBtn{
    color: #D3D7F7;
}
#login-page .registerBtn:hover{
    color: rgb(8, 151, 94);
    cursor: pointer;
}
#login-page .loginBtn:hover{
    color: white;
    background: #1cc09f;
    cursor: pointer;
}
#login-page .title{
    height: 50px;
    color:#D3D7F7;
    font-size: 16px;
    margin-bottom: 0;
}
#login-page .footer{
    position: absolute;
    bottom: 20px;
    left: 35px;
    width: 250px;
    color: #D3D7F7;
    font-size: 10px;
}


/*覆盖antd的默认样式*/
#login-page input{
    color:#1cc09f;
    border:none;
    outline: none;
    box-shadow: none;
    background: transparent;
}
#login-page .ant-input-group-addon{
    background: transparent;
    padding:0;
    border: none;
    color: #fff;
    opacity: 0.8;
}
#login-page .ant-input-group-addon .iconfont{
    display: inline-block;
    width: 30px;
    transition: all .3s;
    opacity: 0.6;
}
#login-page .ant-form-item{
    margin-bottom: 10px;
}
#login-page .ant-form-explain{
    position: absolute;
    z-index: 99;
    left:110%;
    top:0;
    height: 41px;
    /*box-shadow: -2px 2px 2px rgba(0,0,0,.3);*/
}
.login-notification{
    background: transparent;
    border:1px solid #D3D7F7;
    color:#D3D7F7;
    width: 250px;
    height: 80px;
    float: right;
    margin-right: 20px;
}
.login-notification .ant-notification-notice-message{
    color:#D3D7F7;
}
.login-notification .ant-notification-notice-close-x{
    color:#D3D7F7;
}
/*更改谷歌浏览器input背景*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    /*延迟背景颜色载入*/
    -webkit-transition-delay: 99999s;
    -webkit-transition: color 99999s ease-out, background-color 99999s ease-out;
}
.loginCheck {
    color: #bfbfbf;
    margin-bottom: 10px;
    padding: 6px 0 16px 0;
}
.loginCheck .ant-checkbox {
    font-size: 12px;
    line-height: 12px;
}
.loginCheck .ant-checkbox + span {
    padding-left: 12px;
}
.loginCheck .ant-checkbox-inner {
    background: none;
    border: 1px solid #bfbfbf;
    font-size: 12px !important;
    width: 12px;
    height: 12px;
}
.loginCheck .ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: #bfbfbf;
}
.loginCheck .ant-checkbox-inner:after {
    left: 2px;
    top: 4px;
    width: 4px;
    height: 9px;
}