/* @import '~antd/dist/antd.css'; */
body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}
/*HeaderBar*/
.trigger{
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
}
.trigger:hover{
    color: #03af6a;
}
.header-ul{
    display: flex;
}
.header-ul li {
    padding: 0 8px;
}
.header-ul>li img {
    width: 35px;
    height: 35px; 
    border-radius: 100px;
}
.menu{
    line-height: 2em;
    padding: 0 10px;
}
.menu-group li{
    padding: 0 24px;
    line-height: 2em;
}

#page .card-item{
    margin-bottom: 10px;
    border-radius: 3px;
    line-height: 2em;
}
#page .card-ul{
    list-style: inside circle;
}

/*GalleryDemo*/
.aspect {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    overflow: hidden;
}
.aspect--1x2{
    padding-bottom: calc(112.5% + 8px);
}
.aspect--1x1 {
    padding-bottom: 56.25%;
    /*padding的百分比是按父级的width计算的*/
}
.aspect--2x1 {
    padding-bottom: 28.125%;
}
.aspect__inner{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.vertical-center-modal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.vertical-center-modal .ant-modal {
    top: 0;
}

/*美化webkit内核滚动条*/
  ::-webkit-scrollbar {
      width: 11px;
      height: 8px;
  }
::-webkit-scrollbar-thumb {
    background-color: #777;
}

.ant-layout{
    flex: 1
}


/* 修改主题颜色 */
.ant-layout-sider.ant-layout-sider-dark, .ant-menu-dark, .ant-menu-dark .ant-menu-sub{
    background: #108870 !important;
}
/* .ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected{
    background: #1cc09f !important
} */
/*更改antd的primary button样式*/
/* .ant-btn-primary{
    background-color: #00CA79;
    border-color: rgb(5, 182, 111);
}
.ant-btn-primary:hover, .ant-btn-primary:focus, .ant-checkbox-checked .ant-checkbox-inner{
    background-color: rgb(8, 151, 94);
    border-color: rgb(5, 128, 79);
} */
.ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #f5f5f5;
    border-color: #d9d9d9 !important;
}

.ant-layout-header{
    background: #375250;
}

/* .ant-radio-inner::after, .ant-checkbox-checked::after{
    background-color: #00CA79;
}
.ant-radio-checked .ant-radio-inner{
    border: 1px solid #00CA79;
}
.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner, .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner{
    border-color: #00CA79;
}
.ant-input:hover{
    border-color: #00CA79;
}
.ant-radio:focus, .ant-input:focus{
    border-color: #00CA79;
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgb(18, 165, 106, 0.2);
    box-shadow: 0 0 0 2px rgba(18, 165, 106, 0.2)
}

::selection {
    background: #00CA79 !important;
} */

/* .anticon{
    font-size: 18px;
}
.ant-menu-item .anticon, .ant-menu-submenu-title .anticon{
    font-size: 18px;
  } */

/* .selected_count{
    float: left;
} */
.selected_count span span{
    color: red;
}

.ant-table-tbody div>.anticon{
    font-size: 20px;
    margin-right: 8px;
}

.green{
    color: green
}

.ant-modal-body{
    max-height: 700px;
    overflow: auto;
}

.canvasBox canvas {
    display: block;
}